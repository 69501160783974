import React from 'react'
import { Col } from "react-bootstrap";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";

export const TeamCard = ({name,Position, imgUrl, githublink, linkdnlink,animation}) => {
  return (
    <Col size={12} sm={6} md={4}>
      {/* <div className="proj-imgbx mx-auto ">
        <img src={imgUrl} alt="Avtar" />
        
        <div className="proj-txtx">
          <div className="link">
          
          
          </div>
        </div>
        <div >
        <p className='name mx-auto d-flex align-items-center justify-content-center text-white' ></p>
        <p className='name mx-auto d-flex align-items-center justify-content-center text-white'></p>
        </div>
      </div> */}
      
      <div class="our-team">
        <div class="picture">
          <img class="img-fluid" src={imgUrl}/>
        </div>
        <div class="team-content">
          <h3 class="name">{name}</h3>
          <h4 class="title">{Position}</h4>
        </div>
        <ul class="social">
          <li><a href={githublink} target="_blank"><AiFillGithub/></a></li>
          <li><a href={linkdnlink} target="_blank"><AiFillLinkedin/></a></li>
        </ul>
      </div>
    </Col>
  )
}

export default TeamCard