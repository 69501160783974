import React from "react";
import TeamCard from "./TeamCard";
import Iste from "./Iste";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import TrackVisibility from "react-on-screen";
import "./Team.css";
import { AiFillGithub, AiFillLinkedin } from "react-icons/ai";
import {FaTelegramPlane} from "react-icons/fa";

function Team() {
  const core_team = [
    {
      imgUrl: "assests/Team/iste/IMG_1563.jpg",
      name: "Rudra Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/rudrapatel6974",
      Position: "Chief Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1560.jpg",
      name: "Jay Gadhvi",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/jai-gadhavi-0236b2137",
      Position: "Jt Chief Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1543.jpg",
      name: "Neel Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/neelpatel24",
      Position: "Technical Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1561.jpg",
      name: "Sarthak Bhave",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/sarthak-bhave-a4865b222",
      Position: "Jt Technical Coordinator ",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1568.jpg",
      name: "Vikram Tilokani",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/vikram-tilokani-0649201a0",
      Position: "Non tech coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1549.jpg",
      name: "Kush Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/kush-patel-286496216",
      Position: "Jt Non Tech Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1555.jpg",
      name: "Siddharth Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/siddharth-patel-19325324a",
      Position: "Jt Non Tech Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_9641_1.JPG",
      name: "Yash Raval",
      animation: "",
      
      linkdnlink: "http://linkedin.com/in/yash-raval-4982121bb",
      Position: "Finance Secretary",
    },
    {
      imgUrl: "assests/Team/iste/IMG_3000.jpg",
      name: "Harshil Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/harshil-patel-0a990223a",
      Position: "Jt finance Secretary",
    },
    
    {
      imgUrl: "assests/Team/iste/IMG_1552.jpg",
      name: "Jinay Panchal",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/jinay-panchal-b0138226b",
      Position: "Admin Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1557.jpg",
      name: "Om Patel ",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/om-patel-070174193",
      Position: "Promotion Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1553.jpg",
      name: "Khushi Raval",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/khushi-raval-8782a9250",
      Position: "Jt Promotion Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1541.jpg",
      name: "Arpit Parmar",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/arpit-parmar-aa21a125a",
      Position: "Sponsorship Coordinator",
    },
    
    {
      imgUrl: "assests/Team/iste/IMG_1554.jpg",
      name: "Antariksh Sisodiya",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/antarikshsisodiya",
      Position: "Website Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1558.jpg",
      name: "Darshil Parikh",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/darshil-parikh-769406203/",
      Position: "Jt Website Coordinator",
    },
    {
      imgUrl: "assests/Team/IMG_0271.JPG",
      name: "Dhruvyanshi Mahadik",
      animation: "",
      linkdnlink: "",
      Position: "Graphic Designer",
    },
    {
      imgUrl: "assests/Team/IMG_0270.JPG",
      name: "Drashti Patel",
      animation: "",
      linkdnlink: "",
      Position: "Jt Graphic Designer",
    },
    
    {
      imgUrl: "assests/Team/iste/IMG_1539.jpg",
      name: "Kevil Darji",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/kevil-darji-5332191b5",
      Position: "Social-Media Secretary",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1545.jpg",
      name: "Rushi Shah",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/rushi-1902shah",
      Position: "Jt Social-Media Secretary",
    },
    {
      imgUrl: "assests/Team/iste/IMG_3438.jpg",
      name: "Jignesh Patel",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/jignesh-patel-264962257",
      Position: "Cinematography Lead",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1540.jpg",
      name: "Dhruv Mistry",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/dhruv-mistry-a59139227",
      Position: "Decoration Head",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1559.jpg",
      name: "Dhwani Agrawal",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/dhwani-agrawal-95b5b222b",
      Position: "Jt Decoration Head",
    },
    {
      imgUrl: "assests/Team/iste/IMG_4664.jpg",
      name: "Vikas Mistry",
      animation: "",
      
      linkdnlink: "https://www.linkedin.com/in/vikas-mistry-59975824b",
      Position: "Jt Decoration Head",
    },
    
    
  ];

  const developers = [
    {
      imgUrl: "assests/Team/iste/IMG_1554.jpg",
      name: "Antariksh Sisodiya",
      animation: "",
      githublink:"https://github.com/akshsisodiya",
      linkdnlink: "https://www.linkedin.com/in/antarikshsisodiya",
      Position: "Website Coordinator",
    },
    {
      imgUrl: "assests/Team/iste/IMG_1558.jpg",
      name: "Darshil Parikh",
      animation: "",
      githublink:"https://github.com/DarshilTheCoder",
      linkdnlink: "https://www.linkedin.com/in/darshil-parikh-769406203/",
      Position: "Jt Website Coordinator",
    },
    {

      imgUrl: "assests/Team/Parth1.webp",
      name: "Parth vadhwana",
      animation: "",
      githublink: "https://github.com/VadhwanaParth15",
      linkdnlink: "https://www.linkedin.com/in/parth-vadhwana-74b623220",
      Position: "Senior Developer",
    },
    {
      imgUrl: "assests/Team/manav.webp",
      name: "Manav sheth",
      animation: "",
      githublink: "https://github.com/Manavsheth78",
      linkdnlink: "https://www.linkedin.com/in/sheth-manav",
      Position: "Senior Developer",
    },
    {
      imgUrl: "assests/Team/IMG_9810.JPG",
      name: "Karan Megha",
      animation: "",
      githublink: "https://github.com/KaranMegha",
      linkdnlink: "https://www.linkedin.com/in/karan-megha/",
      Position: " Senior Developer",
    },
   
    {
      imgUrl: "assests/Team/IMG_9813.JPG",
      name: "Isha Shah",
      animation: "",
      githublink: "https://github.com/ias1501",
      linkdnlink: "https://www.linkedin.com/in/isha-shah-710594224",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_9759.JPG",
      name: "Dev Jhamtani",
      animation: "",
      githublink: "https://github.com/Jhamtanidev",
      linkdnlink: "https://www.linkedin.com/in/dev-jhamtani-591556218",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_9815.JPG",
      name: "Aryan Thakor",
      animation: "",
      githublink: "https://github.com/Aryan1982",
      linkdnlink: "https://www.linkedin.com/in/aryan-thakore",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_115.JPG",
      name: "Nirav Suthar ",
      animation: "",
      githublink: "http://www.linkedin.com/in/nirav-bugs",
      linkdnlink: "https://github.com/tech-Nirav",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_9709.JPG",
      name: "Diya Shah",
      animation: "",
      githublink: "https://github.com/Diya116",
      linkdnlink: "https://www.linkedin.com/in/shah-diya-6936b0236",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_0013.webp",
      name: "Savan Dangar",
      animation: "",
      githublink: "https://github.com/Savandangar",
      linkdnlink: "https://www.linkedin.com/in/savan-dangar-2a79a6240",
      Position: "Developer",
    },
    {
      imgUrl: "assests/Team/IMG_0271.JPG",
      name: "Dhruvyanshi Mahadik",
      animation: "",
      githublink: "",
      linkdnlink: "",
      Position: "Graphic Designer",
    },
    {
      imgUrl: "assests/Team/IMG_0270.JPG",
      name: "Drashti Patel",
      animation: "",
      githublink: "",
      linkdnlink: "",
      Position: "Graphic Designer",
    },
    {
      imgUrl: "assests/Team/IMG_2023.png",
      name: "Purvesha Prajapati",
      animation: "",
      githublink: "https://github.com/PurveshaPrajapati",
      linkdnlink: "https://www.linkedin.com/in/purvesha-prajapati-169815255",
      Position: "Designer",
    },
    {
      imgUrl: "assests/Team/IMG_0319.png",
      name: "Riddhi Trevadiya",
      animation: "",
      githublink: "https://github.com/Riddhi-trevadiya",
      linkdnlink: "https://www.linkedin.com/in/riddhi-trevadiya",
      Position: "Designer",
    },
    {
      imgUrl: "assests/Team/IMG_0186.JPG",
      name: "Preksha Thoriya",
      animation: "",
      githublink :"https://github.com/Preksha2606",
      linkdnlink: "https://www.linkedin.com/in/preksha-thoriya-353a2724a",
      Position: "Designer",
    },
    
    {
      imgUrl: "assests/Team/Harsh.png",
      name: "Harsh Parmar",
      animation: "",
      githublink: "https://github.com/harshsparmar",
      linkdnlink: "https://www.linkedin.com/in/harsh-parmar-8b9527224",
      Position: "Designer",
    },
   
    {
      imgUrl: "assests/Team/IMG_0189.webp",
      name: "Rutvi Shah",
      animation: "",
      githublink: "https://github.com/rutvishah22",
      linkdnlink: "http://www.linkedin.com/in/rutvishah22",
      Position: "Designer",
    },
    {
      imgUrl: "assests/Team/IMG_0184.JPG",
      name: "Srujal Shah",
      animation: "",
      githublink: "https://github.com/techking007",
      linkdnlink: "https://www.linkedin.com/in/techking007",
      Position: "Designer",
    },
    // {
    //   imgUrl: "assests/Team/IMG_0014.jpg",
    //   name: "Vruddhi Patel",
    //   animation: "",
    //   githublink: "#",
    //   linkdnlink: "#",
    //   Position: "Designer",
    // },
    
  ];

  return (
    <section className="project" id="Team">
      <Container>
        <div className="heading head mx-auto d-flex align-items-center justify-content-center">
          <h2
            className="abouthead "
            // data-aos="zoom-out"
            // data-aos-offset="300"
            // data-aos-easing="ease-in-out"
            // data-aos-delay="500"
          >
            &nbsp;&nbsp;Team
          </h2>
          <h2 className="front-text mx-auto">Team</h2>
        </div>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? "" : ""}>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link className="team" eventKey="first">
                          Core Team
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="team" eventKey="second">
                          Developers
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInDown "
                      className={isVisible ? "" : ""}
                    > 
                      <Tab.Pane eventKey="first">
                        <Row className="align-items-center">
                          {core_team.map((project, index) => {
                            return <Iste key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {developers.map((project, index) => {
                            return <TeamCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Team;
