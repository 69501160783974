import React from 'react'
import './ParticularEventPage.css'
// import workshopimg from '../../assests/Events/ws.png'
import ws from '../../assets/ws.png'
import { useParams } from 'react-router-dom'
import data from '../Events/tech_event.json'
import logo from "../../assets/logo.svg";
// import { LazyLoadImage } from "react-lazy-load-image-component";


const ParticularEventPage = () => {


  const catidtoevent = {
    1: "CYBERINTRUSION",
    2: "WHEELS & WINGS",
    3: "CIRCUITHIVE",
    4: "STRUCTRONICS",
    5: "ARCADIAN EVOLVE",
    6: "S.H.A.R.K.S.",
    7: "HANDS - ON",
    8: "WORKSHOP",
    9: "FAB-LAB",
    10: "Big-Bang Fiesta",
    11: "Adventure Park",
    12: "Valorant",
  }

  const params = useParams()
  const evname = params.evname

  const ev = data.filter(x => x["name"] === evname)[0]
  // console.log(ev)
  const descarray = ev.description.split("\n")
  return (
    <div className="particular-event-page">
      <h1 className="particular-event-page">{ev.name}</h1>
      <div className="ParticularEventPageDiv">
        <div className="displayimgdiv">
          <img src={ev.image} className='displayimg' />

        </div>

        <div className="rightdiv">
          <div className="EventDisc">
            <div className="div">
              <h2>Description</h2>
              <div className="disc">{descarray.map(d => {
                return <p>{d}</p>
              })}</div>
            </div>
          </div>
          <div className="bottomdiv">
            <h2 className='priceh2'>{ev.price}</h2>
            <a href={ev.link} target="blank"><button className='registerbtn'><p>Participate Now</p></button></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParticularEventPage;