import React from "react";
import GlimpseCard from "./GlimpseCard";
import "./Glimpse.css";
const Events = [
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/LAUNCH/AQADELkxG8CEKVV-.webp",
    key: 1,
    Animation: "fade-right",
  },
  // {
  //   EventimageSource: "assests/Glimpse/DSC_2546.jpg",
  //   key: 2,
  //   Animation: "flip-up",
  // },
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/LAUNCH/AQADC7kxG8CEKVV-.webp",
    key: 3,
    Animation: "flip-down",
  },
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/LAUNCH/AQADD7kxG8CEKVV-.webp",
    key: 4,
    Animation: "fade-left",
  },
  {
    EventimageSource: "/assests/Glimpse/1 (3).jpg",
    key: 5,
    Animation: "fade-right",
  },
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/LAUNCH/IMG_9463.JPG?t=2023-03-30T11%3A57%3A15.688Z",
    key: 6,
    Animation: "flip-up",
  },
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/glimpse/DSC_3751.webp",
    key: 7,
    Animation: "flip-down",
  },
  {
    EventimageSource: "https://fvqsfvaqamttbvmxzugt.supabase.co/storage/v1/object/public/static/LAUNCH/AQADDrkxG8CEKVV-.webp",
    key: 8,
    Animation: "fade-left",
  },
  //   {
  //     EventimageSource: "assests/Glimpse/DSC_3773.jpg",
  //     key: 9,
  //     Animation: "fade-left",
  //   },
  // {
  //   EventimageSource: "assests/Glimpse/DSC_3773.jpg",
  //   key: 10,
  //   Animation: "fade-right",
  // },
  // {
  //   EventimageSource: "assests/Glimpse/DSC_3773.jpg",
  //   key: 11,
  //   Animation: "fade-left",
  // },
];
const Glimpse = () => {
  return (
    <section id="Glimpse">
      <div className="gdiv">
        <div className="heading">
          <h2
            className="abouthead"
          // data-aos="zoom-out"
          // data-aos-offset="300"
          // data-aos-easing="ease-in-out"
          // data-aos-delay="500"
          >
            Glimpse
          </h2>
          <h2 className="front-text"> Previous Events </h2>
        </div>
        <div className="imagecontainer">
          <div className="h222">
            {Events.map((members) => (
              <GlimpseCard
                item={members}
                src={members.EventimageSource}
                key={members.key}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Glimpse;
