import React from 'react'
import { Link } from 'react-router-dom'

import './EventPage.css'
import workshopimg from "./../../assets/ws.png"
import { useParams } from 'react-router-dom'
import tech_event from '../Events/tech_event.json'


const EventPage = () => {
    const catidtoevent = {
        1: "CYBERINTRUSION",
        2: "WHEELS & WINGS",
        3: "CIRCUITHIVE",
        4: "STRUCTRONICS",
        5: "ARCADIAN EVOLVE",
        6: "S.H.A.R.K.S.",
        7: "HANDS - ON",
        8: "WORKSHOP ",
        9: "FAB-LAB",
        10: "BIG BANG FIESTA",
        11: "ADVENTURE PARK",
        12: "VALORANT ",
    }
    const catid = useParams().catid
    const catname = catidtoevent[catid]

    const catevent = tech_event.filter((e => e["categories"] === catname))

    return (
        <div className="container mb-5">


            {
                catevent.map(ev => {
                    return (
                        <div id='eventpage' className="EventPagebody container-fluid">
                            <div className='workshops row'>
                                <div className='col-lg-5 d-flex justify-content-center p-3'><img className="workshopimg" src={ev.image || workshopimg} /></div>
                                <div className="col-lg-7 about">
                                    <h1 className="event-name">{ev.name}</h1>
                                    <p className='tagline'>{ev.tagline}
                                    </p>
                                    <Link to={`/event/${ev.name}`}>

                                        <button type="button" className="btn btnevent btn-primary">Read More</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default EventPage