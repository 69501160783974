import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Event.css'
import { Link } from 'react-router-dom'
import { Col } from "react-bootstrap";
import 'react-bootstrap'
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import logo from "../../assets/logo.svg"


export const EventCard = ({ imgurl, title, description, link, catid }) => {

  return (
    <Link to={`/cat/${catid}`} style={{ textDecoration: 'none' }}>
      <div className=' bg'>
        <Card className='card mx-auto d-flex align-items-center justify-content-center'>
          <Card.Img className='mx-auto d-flex align-items-center justify-content-center imgc ' variant="top" src={imgurl} />
          <Card.Body>
            <Card.Title className='title mx-auto d-flex align-items-center justify-content-center'>{title}</Card.Title>
            <Card.Text className='description  mx-auto d-flex align-items-center justify-content-center'>
              {description}
            </Card.Text>

            <Button className='mx-auto d-flex align-items-center justify-content-center button' variant="primary">view more</Button>


          </Card.Body>
        </Card>
      </div>
    </Link >
  )
}

export default EventCard