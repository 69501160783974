import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Sponsor.css";
import { Col } from "react-bootstrap";
import "react-bootstrap";


export const SponserCard = () => {
  return (
    <section className="container" id="Sponser">
      <div className="heading head mx-auto d-flex align-items-center justify-content-center">
        <h2
          className="abouthead text-align-center justify-content-center"
        // data-aos="zoom-out"
        // data-aos-offset="300"
        // data-aos-easing="ease-in-out"
        // data-aos-delay="500"
        >
          Sponsor
        </h2>
        <h2 className="front-text mx-auto">Sponsor</h2>
      </div>
      <div className="row">
      
        <div className="col-lg-6">

          <div className="titlesponser">
            <h1> Title Sponsor</h1>
            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              >
                <a target="_blank" href="https://globalcolliance.com/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/GC.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="titlesponser ">
            <h1> Technology Partner  </h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/vartix.jpg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>


      {/* row 1 */}
      <div className="row">
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> CAT Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              >
                <a target="_blank" href="https://www.time4education.com/">

                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/time.webp"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Banking Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              >
                <a target="_blank" href="https://www.icicibank.com/">

                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3 "
                    variant="top"
                    src="assests/Sponsor/icici.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> GATE Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://acumenhr.in/homegate.php">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/acumen.jpg"
                  />

                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>


      {/* row 2 */}
      <div className="row">
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Career Partner </h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://www.libertygroup.in/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/liberty.jpeg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Learning Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://youtube.com/@PriyeshsirVidhyapeeth">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3 "
                    variant="top"
                    src="assests/Sponsor/pv.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Fashion Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://instagram.com/graphicandgarment?igshid=YmMyMTA2M2Y=">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/gd.jpeg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* row 3 */}
      <div className="row">
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Toy & Gift Partner  </h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://www.hamleys.in/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/hamleys.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Sports Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://www.decathlon.in/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3 "
                    variant="top"
                    src="assests/Sponsor/decathlon.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Fitness Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://instagram.com/powerhouse0902?igshid=YmMyMTA2M2Y=">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/powerhouse.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* row 4 */}
      <div className="row">
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Venue Partner  </h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="http://evathemall.com/	">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/eva.jpg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Salon Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://aonehnb.com/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3 "
                    variant="top"
                    src="assests/Sponsor/aone.webp"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Watch & Lifestyle Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://business.google.com/website/dalson">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/dalson.jpg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* row 5 */}
      <div className="row">
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Drone Partner  </h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://orbitfly-drones.business.site/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/orbitfly.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> Photography and Videography Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://instagram.com/sonaliphotostudio?igshid=YmMyMTA2M2Y=">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3 "
                    variant="top"
                    src="assests/Sponsor/sonali.png"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="titlesponser ">
            <h1> NEWS Media Partner</h1>

            <div className="arrange">
              <Card
                className="card mx-auto d-flex align-items-center justify-content-center bg-light"

              ><a target="_blank" href="https://www.vnmtvnews.com/">
                  <Card.Img
                    className="mx-auto d-flex align-items-center justify-content-center imgc p-3"
                    variant="top"
                    src="assests/Sponsor/vnm.jpg"
                  />
                </a>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponserCard;
