import React from 'react'
import './Event.css'
import { EventCard } from './EventCard'
import { Row, Col } from "react-bootstrap";
import bg from '../../assets/bgprakarsh.png';
import { useEffect, useState } from 'react';
// import supabase from '../../lib/client'


function Event() {
  // console.log(data);
  const event = [
    {
      imgurl: "assests/Events/cyber.png",
      title: "cyber intrusion",
      description: "Tech-Tastic Fun: Discovering the Joy of Innovation",
      link: "",
      catid: "1"
    },
    {
      imgurl: "assests/Events/wheels.png",
      title: "wheels & wings",
      description: "Discover the Fusion of Tech, Mech, and Aero with Our Events",
      link: "",
      catid: "2"
    },
    {
      imgurl: "assests/Events/circuithive.png",
      title: "Circuithive",
      description: "Building the Future, One Circuit at a Time",
      link: "",
      catid: "3"
    },
    {
      imgurl: "assests/Events/structonics.png",
      title: "structonics",
      description: "Building a Sustainable Future Together",
      link: "",
      catid: "4"
    },
    {
      imgurl: "assests/Events/innovative.png",
      title: "arcadian evovle",
      description: "Learn, Celebrate, and Impact Rural Development",
      link: "",
      catid: "5"
    },
    {
      imgurl: "assests/Events/sharks.png",
      title: "S.H.A.R.K.S",
      description: "Unleash the Power of Entrepreneurship",
      link: "",
      catid: "6"
    },
    {
      imgurl: "assests/Events/handson.png",
      title: "Hands-on",
      description: "Take your learning to the next level with our dynamic, hands-on approach",
      link: "",
      catid: "7"
    },
    {
      imgurl: "assests/Events/ws.png",
      title: "workshop",
      description: "Empower Your Skills: Learn,Build and Grow with Our Technical Workshops!",
      link: "/EventPage",
      catid: "8",
    },
    {
      imgurl: "assests/Events/fab.png",
      title: "Fab-Lab",
      description: "Join the Digital Revolution: Explore the Possibilities at our Computer Lab Events ",
      link: "",
      catid: "9",
    },
    {
      imgurl: "assests/Events/Big bang.png",
      title: "Big-Bang Fiesta",
      description: "Get ready to play and party like it's the beginning of time at Big Bang Fiesta!",
      link: "",
      catid: "10",
    },
    {
      imgurl: "assests/Events/adventurepark.png",
      title: "Adventure Park",
      description: "Experience the thrill of a lifetime at our adventure park - where adventure never stops!",
      link: "",
      catid: "11"
    },
    {
      imgurl: "assests/Events/valorant.png",
      title: "Valorant ",
      description: "Step into the battleground with Valorant - where every round is a chance to prove your valor",
      link: "",
      catid: "12"
    },
  ];

  // const [event, setEvent] = useState([]);
  // useEffect(() => {
  //   async function getEvents() {
  //     let { data, error } = await supabase.from("Event").select("*").eq("catagory", "cyber intrusion");
  //     setEvent(data);
  //     console.log(data);
  //     console.log(error);
  //   }

  //   getEvents();
  // }, []);

  return (

    <section className="container cata" id="TechFest">

      {/* <div className="heading head mx-auto d-flex align-items-center justify-content-center">
        <h2
          className="abouthead  justify-content-center"
        // data-aos="zoom-out"
        // data-aos-offset="300"
        // data-aos-easing="ease-in-out"
        // data-aos-delay="500"
        >
          &nbsp;&nbsp;Events
        </h2>
        <h2 className="front-text mx-auto">Events</h2>
      </div> */}
      <Row>

        {event.map((members) =>
          <Col key={members.catid}>
            <EventCard
              item={members}
              imgurl={members.imgurl}
              title={members.title}
              description={members.description}
              link={members.link}
              catid={members.catid}
            />
          </Col>
        )}
      </Row>

    </section>
  );

}

export default Event