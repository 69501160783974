import React from 'react'
import './Event.css'
import { EventCard } from './SEventCard'
import { Row, Col } from "react-bootstrap";
import bg from '../../assets/bgprakarsh.png';
import { useEffect, useState } from 'react';
import supabase from '../../lib/client'


function Event() {
  const event = [
    {
      imgurl: "assests/Events/cyber.png",
      title: "TechFest",
      description: "Unleashing Innovation: Where Tech and Creativity Meet",
      link: "/TechFest",
    },
    {
      imgurl: "assests/Events/avantexpoimg.png",
      title: "Avant expo",
      description: "Sankalp -  Sahyog- Samutkarsh",
      link: "https://avantexpo.prakarsh.org",
    },
    {
      imgurl: "assests/Events/openrimg.png",
      title: "Open-R",
      description: "Code for the CountrySide",
      link: "https://openr.prakarsh.org",
    },

  ];
  //https://avantexpo.prakarsh.org

  // const [event, setEvent] = useState([]);
  // useEffect(() => {
  //   async function getEvents() {
  //     let { data, error } = await supabase.from("Event").select("*").eq("catagory", "cyber intrusion");
  //     setEvent(data);
  //   }

  //   getEvents();
  // }, []);

  return (

    <section className="container" id="SEvents">

      <div className="heading head mx-auto d-flex align-items-center justify-content-center">
        <h2s
          className="abouthead  justify-content-center"
        // data-aos="zoom-out"
        // data-aos-offset="300"
        // data-aos-easing="ease-in-out"
        // data-aos-delay="500"
        >
          &nbsp;&nbsp;Event
        </h2s>
        <h2 className="front-text mx-auto">Events</h2>
      </div>
      <Row>

        {event.map((members) => (
          <Col>
            <EventCard
              item={members}
              imgurl={members.imgurl}
              title={members.title}
              description={members.description}
              link={members.link}

            />
          </Col>
        ))}
      </Row>

    </section>
  );

}

export default Event